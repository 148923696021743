export default defineNuxtRouteMiddleware(async (to: any, from: any) => {
    const config = useRuntimeConfig();

    // Prevent robot.txt to show every request
    if (to.path.includes('robot.txt')) return;

    // Retain query URL
    const queryURL: URLSearchParams = new URLSearchParams(from.query);
    const query: string = queryURL.toString() ? `?${queryURL}` : '';

    // Get Storyblok cache version
    const storyblokApi = useStoryblokApi();
    const cv = await storyblokApi.get('cdn/spaces/me');
    const startsWith = `${config.public.STORYBLOK_FOLDER}/global/redirects/`;

    const perPage = 100;
    const params = {
        version: config.public.STORYBLOK_VERSION,
        per_page: perPage,
        page: 1,
    };

    let stories: any = [];
    let total: number = 0;

    // Get Data from Storyblok (UseFetch to cache result)
    const firstRequest: any = await useFetch(
        `https://api.storyblok.com/v2/cdn/stories?starts_with=${startsWith}&per_page=${params.per_page}&page=${params.page}&version=${config.public.STORYBLOK_VERSION}&token=${config.public.STORYBLOK_TOKEN}&cv=${cv.data.space.version}`,
        {
            onResponse(context) {
                // Output all available response headers
                total = Number(context.response.headers.get('total'));
            },
        }
    );

    stories = stories.concat(firstRequest.data.value.stories);

    const lastPage = total ? Math.ceil(total / perPage) : 1;
    for (let currentPage = 2; currentPage <= lastPage; currentPage++) {
        params.page = currentPage;
        const { data }: any = await useFetch(
            `https://api.storyblok.com/v2/cdn/stories?starts_with=${startsWith}&per_page=${params.per_page}&page=${currentPage}&version=${config.public.STORYBLOK_VERSION}&token=${config.public.STORYBLOK_TOKEN}&cv=${cv.data.space.version}`
        );
        stories = stories.concat(data.value.stories);
    }

    // Get URL without trailing slash
    const toUrl: string = to.path.endsWith('/') ? to.path.slice(0, to.path.length - 1) : to.path;

    // Get result from Storyblok results, if no results don't redirect
    const redirect = stories?.filter((r: { content: { source_url: string } }) => {
        return r.content.source_url === toUrl;
    })[0];
    if (!redirect) return;

    // Check if URL + Storyblok target is the same, if so don't redirect
    if (redirect.content.target_story === toUrl) return;

    return navigateTo(redirect.content.target_story + query, {
        external: true,
        redirectCode: Number(redirect.content.status_code),
    });
});
