import { default as _91_46_46_46slug_93hq5w5chgKrMeta } from "/codebuild/output/src2963374668/src/bitbucket.org/magusa/cavu-workspaces/apps/_base/pages/[...slug].vue?macro=true";
import { default as healthJ6ZdSTnUxbMeta } from "/codebuild/output/src2963374668/src/bitbucket.org/magusa/cavu-workspaces/apps/jetpark/pages/health.vue?macro=true";
import { default as component_45stubUIknYndypgMeta } from "/codebuild/output/src2963374668/src/bitbucket.org/magusa/cavu-workspaces/node_modules/.pnpm/nuxt@3.14.1592_@parcel+watcher@2.5.0_@types+node@22.10.1_eslint@8.57.1_ioredis@5.4.1_magicast_3hdc3bps45nwttuoyom2znimhe/node_modules/nuxt/dist/pages/runtime/component-stub.js?macro=true";
import { default as component_45stubUIknYndypg } from "/codebuild/output/src2963374668/src/bitbucket.org/magusa/cavu-workspaces/node_modules/.pnpm/nuxt@3.14.1592_@parcel+watcher@2.5.0_@types+node@22.10.1_eslint@8.57.1_ioredis@5.4.1_magicast_3hdc3bps45nwttuoyom2znimhe/node_modules/nuxt/dist/pages/runtime/component-stub.js";
export default [
  {
    name: "slug___en",
    path: "/:slug(.*)*",
    component: () => import("/codebuild/output/src2963374668/src/bitbucket.org/magusa/cavu-workspaces/apps/_base/pages/[...slug].vue")
  },
  {
    name: "health___en",
    path: "/health",
    component: () => import("/codebuild/output/src2963374668/src/bitbucket.org/magusa/cavu-workspaces/apps/jetpark/pages/health.vue")
  },
  {
    name: component_45stubUIknYndypgMeta?.name,
    path: "/sitemap.xml",
    component: component_45stubUIknYndypg
  }
]